* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Montserrat, Roboto, sans-serif !important;
}

img {
    max-width: 100%;
    height: auto;
}

body {
    max-width: 100%;
    overflow-x: hidden;
}

body {
    background-color: #f6f6f6;
}

h1 {
    color: #4C4C4C;
    font-weight: 700;
    font-size: 60px;
    text-align: center;
}

h4 {
    margin-top: 24px;
    color: #F4980D;
    font-weight: 600;
    font-size: 18px;
}

h5 {
    margin-top: 24px;
    color: #F4980D;
    font-weight: 600;
    font-size: 15px;
}

h3 {
    color: #F4980D;
    font-weight: 700;
    font-size: 25px;
    text-align: center;
}

p {
    color: #828282;
    font-weight: 600;
    font-size: 20px;
    margin-top: 12px;
}

.primary-button {
    padding: 0.9rem 1.75rem;
    background-color: white;
    outline: none;
    border: none;
    border-radius: 5rem;
    font-size: 1.1rem;
    cursor: pointer;
    font-weight: 600;
    transition: 0.2s;
}

.secondary-button {
    padding: 1rem 2.5rem;
    background-color: #fe9e0d;
    outline: none;
    border: none;
    border-radius: 5rem;
    font-size: 1.1rem;
    cursor: pointer;
    font-weight: 600;
    color: white;
    transition: 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
}

.google-play-badge {
    max-height: 96px;
}

.cta-main-bg {
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.cta-main-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.65);
}

.cta-title {
    color: white;
    font-size: 72px;
}

.cta-description {
    font-size: 32px;
    color: white;
    margin-top: 16px;
}

.latest-product-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 4%;
}

.latest-product-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 32px;
}

.latest-product-column {
    flex: 1;
    padding: 24px;
    text-align: center;
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media (max-width: 768px) {
    .latest-product-container {
        flex-direction: column;
    }

    .latest-product-column {
        flex: auto;
    }
}

.previous-work-main {
    padding: 4%;
}

.previous-work-image-grid {
    padding: 4%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 320px));
    gap: 24px;
    justify-content: center;
}

.previous-work-image-grid-item {
    width: 100%;
    height: 100%;
}

.previous-work-image-grid-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.footer-main {
    text-align: center;
    padding: 32px;
}

.privacy-policy-main-container {
    padding: 2% 4%;
}

.privacy-policy-main-title {
    margin-top: 24px;
}

.privacy-policy-main-container p {
    font-size: 15px;
    margin-top: 20px;
}

.privacy-policy-main-container ul {
    margin-top: 20px;
}

.privacy-policy-main-container li {
    color: #828282;
    font-weight: 600;
    margin-top: 8px;
    font-size: 15px;
}

.not-found-main {
    display: table;
    width: 100%;
    height: 90vh;
    text-align: center;
}

.not-found-content {
    display: table-cell;
    vertical-align: middle;
}

.not-found-content h1{
    font-size: 50px;
    display: inline-block;
    padding-right: 12px;
    animation: type .5s alternate infinite;
}

.not-found-content *{
    transition: all 0.6s;
}

@keyframes type{
    from{box-shadow: inset -3px 0px 0px #888;}
    to{box-shadow: inset -3px 0px 0px transparent;}
}